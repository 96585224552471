import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

const PhotoGrid = ({photos, title, showArrow, numPhotos, totalCount}) => {

  if (numPhotos) {
    numPhotos = Math.min(photos.length - (photos.length % 4), numPhotos);
    photos = photos.slice(0,numPhotos)
  }

  if (photos.length == 0) {
    return (null);
  }

  let totalCountLink; 
  if (totalCount) {
    totalCountLink = <Link className="more-link" to={photos[0].fields.cityFullPath}>View all {totalCount} &rarr;</Link>;
  } else {
    totalCountLink = null;
  }

  return (
      <div>
      <div className="gallery">
        {photos.map((item, i) => (
          <div className="item" key={item.id}>
            <Link to={item.fields.path}>
              <Img fluid={item.images[0].src.childImageSharp.fluid} />
            </Link>
            {/* <div class="location-meta">
              {item.images[0].title}, {item.city}. {item.images[0].date}
            </div> */}
          </div>
        ))}
      </div>
      {totalCountLink}
      </div>
  )
}

export default PhotoGrid
